import { useBi as useBILogger } from '@wix/yoshi-flow-editor';
import {
  actionButtonClickedParams,
  memberJoinedCommunityAttemptParams,
  profileWidgetEditedParams,
} from '@wix/bi-logger-members-app-uou';

import { getBiProfileSaveData } from './getBiProfileSaveData';
import { useMember } from '../../contexts/widget';
import { ProfilePrivacyStatus } from '../../types/member';
import {
  BiActionType,
  Field,
  FieldPrivacy,
  MY_ACCOUNT_REFERRAL,
} from '../../types';

enum BiPrivacy {
  Only_Me = 'only_me',
  All_Members = 'all_members',
}

interface ProfileEditedProps {
  changedFields: Field[];
  changedPrivacyFields: Field[];
  urlChanged: boolean;
}

const saveOrigin = 'my_account';

const getBiPrivacy = (newPrivacy: FieldPrivacy) =>
  newPrivacy === FieldPrivacy.PRIVATE
    ? BiPrivacy.Only_Me
    : BiPrivacy.All_Members;

const isProfilePublic = (status: ProfilePrivacyStatus) =>
  status === ProfilePrivacyStatus.PUBLIC;

const getChangedPrivacyFieldsBIString = (
  changedPrivacyFields: Field[],
  fieldPrivacy: FieldPrivacy[],
) =>
  changedPrivacyFields
    .filter((field) => fieldPrivacy.includes(field.privacy))
    .map((field) => field.label)
    .join(',');

export const useBi = () => {
  const biLogger = useBILogger();
  const { privacyStatus, role } = useMember();

  const withCommonProps = (
    props?:
      | profileWidgetEditedParams
      | actionButtonClickedParams
      | memberJoinedCommunityAttemptParams,
  ) => {
    const common = {
      ...(role && { role }),
      is_social: isProfilePublic(privacyStatus),
    };
    return { ...common, ...(props && { ...props }) };
  };

  const profileEdited = ({
    changedPrivacyFields = [],
    changedFields = [],
    urlChanged = false,
  }: Partial<ProfileEditedProps>) => {
    const biProfileSaveData = changedFields
      ? getBiProfileSaveData(changedFields)
      : null;
    const privacyOnlyMe = getChangedPrivacyFieldsBIString(
      changedPrivacyFields,
      [FieldPrivacy.PRIVATE],
    );
    const privacyAllMembers = getChangedPrivacyFieldsBIString(
      changedPrivacyFields,
      [FieldPrivacy.PUBLIC],
    );
    const privacyChanged = changedPrivacyFields.length > 0;

    biLogger.profileWidgetEdited(
      withCommonProps({
        saveOrigin,
        privacyChanged,
        urlChanged,
        ...(biProfileSaveData && { ...biProfileSaveData }),
        ...(privacyOnlyMe && { privacyOnlyMe }),
        ...(privacyAllMembers && { privacyAllMembers }),
      }),
    );
  };

  const fieldPrivacyChanged = (
    fieldName: string,
    newFieldPrivacy: FieldPrivacy,
    fieldType: string,
  ) => {
    const value = getBiPrivacy(newFieldPrivacy);
    biLogger.myAccountPrivacySettingsChanged({ fieldName, value, fieldType });
  };

  const ctaButtonClicked = (action_type: BiActionType) => {
    biLogger.actionButtonClicked(
      withCommonProps({
        action_type,
      }),
    );
  };

  const ctaButtonClickedWithProps = (action_type: BiActionType, props: any) => {
    biLogger.actionButtonClicked(
      withCommonProps({
        action_type,
        ...props,
      }),
    );
  };

  const leaveCommunityAttempt = () =>
    biLogger.memberLeftCommunityAttempt(
      withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
    );

  const joinCommunityAttempt = () =>
    biLogger.memberJoinedCommunityAttempt(
      withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
    );

  const leftCommunity = () =>
    biLogger.memberLeftCommunityLeftAfterPopup(
      withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
    );

  const joinedCommunity = () =>
    biLogger.memberJoinedCommunityJoinedAfterPopUpUou(
      withCommonProps({ referralInfo: MY_ACCOUNT_REFERRAL }),
    );

  const blockedMembersListOpen = (membersCount: number) => {
    biLogger.blockedMembersListOpen({
      membersCount,
      referralinfo: MY_ACCOUNT_REFERRAL,
    });
  };

  return {
    profileEdited,
    fieldPrivacyChanged,
    ctaButtonClicked,
    ctaButtonClickedWithProps,
    leaveCommunityAttempt,
    joinCommunityAttempt,
    leftCommunity,
    joinedCommunity,
    blockedMembersListOpen,
  };
};
